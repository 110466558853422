import Footer from "./Footer";
import MenuBar from "./MenuBar";
import pencil from "./pencil.svg";
import "./PublicationsPage.css";
function PublicationsPage()
{
    return (
        <div className="PageBackground">
        <div className="PublicationsPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>
            <div className="PublicationsContainer">
                <div className="PublicationsList">
                    <div className="PublicationItem">
                        <img src={pencil}/>
                        <p><a href="https://arxiv.org/pdf/2407.11485">Adela Ljajić, Miloš Košprdić, Bojana Bašaragin, Darija Medvecki, Lorenzo Cassano, Nikola Milošević, “Scientific QA System with Verifiable Answers”, The 6th International Open Search Symposium 2024</a></p>
                    </div>
                    <div className="PublicationItem">
                        <img src={pencil}/>
                        <p><a href="https://arxiv.org/pdf/2402.18589">Košprdić, M., Ljajić, A., Bašaragin, B., Medvecki, D., & Milošević, N. "Verif. ai: Towards an Open-Source Scientific Generative Question-Answering System with Referenced and Verifiable Answers." The Sixteenth International Conference on Evolving Internet INTERNET 2024 (2024).</a></p>
                    </div>
                    <div className="PublicationItem">
                        <img src={pencil}/>
                        <p><a href="https://aclanthology.org/2024.bionlp-1.44/">Bojana Bašaragin, Adela Ljajić, Darija Medvecki, Lorenzo Cassano, Miloš Košprdić, Nikola Milošević "How do you know that? Teaching Generative Language Models to Reference Answers to Biomedical Questions", Accepted at BioNLP 2024, Colocated with ACL 2024</a></p>
                    </div>
                    <div className="PublicationItem">
                        <img src={pencil}/>
                        <p><a href="https://belbi.bg.ac.rs/abstract/enhancing-biomedical-information-retrieval-with-semantic-search-a-comparative-analysis-using-pubmed-data/">Adela Ljajić, Lorenzo Cassano, Miloš Košprdić, Bojana Bašaragin, Darija Medvecki, Nikola Milošević "Enhancing Biomedical Information Retrieval with Semantic Search: A Comparative Analysis Using PubMed Data", Belgrade Bioinformatics Conference 2024, BelBi2024</a></p>
                    </div>
                </div>
            </div>

            <div className="FooterDiv">
                    <Footer/>
                </div>
            
           
        </div>
        </div>
    )
}

export default PublicationsPage;